import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppSettingModel } from 'src/models/app-setting.model';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {
  private appSettingSubject: BehaviorSubject<AppSettingModel>;
  public appSetting$: Observable<AppSettingModel>;

  constructor(private http: HttpClient) {
    this.appSettingSubject = new BehaviorSubject<AppSettingModel>(null);
    this.appSetting$ = this.appSettingSubject.asObservable();
  }

  public get value(): AppSettingModel {
    return this.appSettingSubject.value;
  }

  update(appSetting: AppSettingModel){
    this.appSettingSubject.next(appSetting);
  }

  get(): Observable<any> {
    return this.http.get(`${environment.apiUrl}app_settings/`, { withCredentials: true }) as Observable<any>;
  }

}
