import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountriesModel } from 'src/models/countries.model';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  private countriesSubject: BehaviorSubject<Array<CountriesModel>>;
  public countries$: Observable<Array<CountriesModel>>;

  constructor(private http: HttpClient) {
    this.countriesSubject = new BehaviorSubject<Array<CountriesModel>>([]);
    this.countries$ = this.countriesSubject.asObservable();
  }

  public get value(): Array<CountriesModel> {
    return this.countriesSubject.value;
  }

  update(countries: Array<CountriesModel>){
    this.countriesSubject.next(countries);
  }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}countries/`) as Observable<any>;
  }

  all(): Observable<Array<CountriesModel>> {
    return this.http.get(`${environment.apiUrl}countries/all/`) as Observable<Array<CountriesModel>>;
  }
}
