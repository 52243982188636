import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { FaqModel } from 'src/models/faq.model';
import { FaqService } from 'src/services/faq/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqs: Array<FaqModel>;

  constructor(public faqService: FaqService, private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "FAQ" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });
  }

}
