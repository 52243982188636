import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BuyerModel } from 'src/models/buyer.model';
import {User} from "../../models/user.model";

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}buyer/`) as Observable<any>;
  }

  currentBuyerInformation(): Observable<any> {
    return this.http.get(`${environment.apiUrl}buyer/current_buyer_info/`) as Observable<any>;
  }

  update(slug: string, value: any): Observable<BuyerModel> {
    return this.http.put(`${environment.apiUrl}buyer/${slug}/`, value) as Observable<BuyerModel>;
  }

  removeCollegue(slug: string): Observable<User> {
      return this.http.put(`${environment.apiUrl}buyer/remove_collegue/`, slug) as Observable<User>;
  }
}
