<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>APPELS D'OFFRES</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>APPELS D'OFFRES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="job-area job-area-two pt-5">
    <div class="container">
        <form class="search-form">
            <input type="text" class="form-control" [(ngModel)]="search" placeholder="Saisir le nom d'un appel" name="search" autocomplete="off">
            <button class="btn subscribe-btn" type="submit"><i class="icofont-search" style="font-weight: bold;"></i></button>
        </form>
    </div>
    <div class="container pt-5">
        <div class="row">

            <div class="col-lg-4">
                <div class="blog-details-item">
                    <div class="single-resume-category">
                        <h3>SECTEURS D'ACTIVITÉ</h3>
                        <ul>
                            <li *ngFor="let category of categoryService.value">
                                <a style="cursor: pointer;" (click)="onClick(category.slug)"> {{ category.name }} </a>
                                <span>({{ category.number_of_requests }})</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <app-loading [loading]="loading" />
                <ng-container *ngIf="requests">
                    <div class="job-item bg-white wow fadeInUp" data-wow-delay=".3s" *ngFor="let request of filteredRequests">
                        <img [ngSrc]="request.buyer.logo" width="60" height="60" [alt]="request.buyer.name">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>{{ request.name }}</h3>
                                <ul>
                                    <li><span class="badge text-bg-{{ request.status == 'published' ? 'success' : request.status == 'expired' ? 'danger' : 'danger' }} rounded-0">{{ request.status == 'published' ? "EN COURS" : request.status == 'expired' ? 'EXPIRÉ' : 'ANNULÉ' }}</span></li>
                                    <li><i class="icofont-wall-clock"></i> Date de publication: {{ request.published_at| date:'d MMMM yyyy' }}</li>
                                    <li><i class="icofont-wall-clock"></i> Date Limite: {{ request.expired_at|date:'d MMMM yyyy' }}</li>
                                    <li>

                                        <span style="margin-right: 10px;" *ngFor="let category of request.categories" class="badge text-bg-success rounded-0">{{ category.name }}</span>
                                     </li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/supplier/request/{{ request.slug }}">VOIR DETAIL</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>>
                    <!--div class="list-group">
                        <div [routerLink]="['/supplier/request',request.slug]" class="list-group-item request-item rounded-0 d-flex justify-content-between align-items-center wow fadeInUp" data-wow-delay=".3s" *ngFor="let request of filtRequests || requests">
                            <div>
                                <div class="row">
                                    <div class="col-lg-2">
                                        <img [src]="request.buyer.logo" width="50" [alt]="request.buyer.name">
                                    </div>
                                    <div class="col-lg-10">
                                        <h5 class="mb-1"><strong>{{ request.name }}</strong></h5>
                                        <p class="mb-1">Date de publication: {{ request.published_at | date : "d, MMM yyyy" }} - Date d'expiration: {{ request.expired_at | date : "d, MMM yyyy" }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="badge bg-secondary me-3 rounded-0 p-2">{{ request.jour_restant }} jours restants</span>
                                <span class="badge border-0 rounded-0 me-3 p-2" [ngClass]="request.status == 'published' ? 'text-bg-success' : request.status == 'rejected' ? 'text-bg-danger' : request.status == 'aborded' ? 'text-bg-danger' : request.status == 'expired' ? 'text-bg-danger' : 'text-bg-warning'">
                                    {{ request.status == 'published' ? 'EN COURS' : request.status == 'rejected' ? 'RÉJETÉ' : request.status == 'aborded' ? 'ANNULÉ' : request.status == 'expired' ? 'EXPIRÉ' : 'BROUILLON' }}
                                </span-->
                                <!-- <span class="badge bg-success me-3 status en-cours">En cours</span> -->
                                <!-- <a href="#" class="btn btn-outline-primary">Voir Détail</a> -->
                            <!--/div>
                        </div>

                    </div-->
                    <div class="d-flex justify-content-between mt-5">
                        <ngb-pagination
                            [collectionSize]="collectionSize"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            (pageChange)="refreshCompanies()"
                        >
                        </ngb-pagination>
                    </div>
                </ng-container>

                <ng-container *ngIf="requests">
                    <div class="container" *ngIf="requests.length == 0">
                        <div class="text-center">
                            <img ngSrc="./assets/img/notdatafound.png" alt="" width="554" height="450"/>
                            <div class="pt-5">
                                <h4>Aucun appel d'offre de cette catégorie trouvé</h4>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</section>
