<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>A PROPOS DE LA BSTP</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>A PROPOS DE LA BSTP</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="portal-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-7 wow fadeInUp" data-wow-delay=".3s">
                            <img src="./assets/img/upload/about/359x522.png" alt="Portal" />
                        </div>

                        <div class="col-lg-5 wow fadeInUp" data-wow-delay=".5s">
                            <img src="./assets/img/upload/about/245x331.png" alt="Portal" />
                        </div>
                    </div>

                    <!-- <div class="portal-trusted">
                        <span>100% Garantie</span>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="portal-item portal-right portal-right-two pb-5">
                    <h2 class="animate__animated animate__bounceInRight wow" data-wow-delay=".3s">Bourse des Sous-traitance et de Partenariats</h2>
                    <p style="text-align: justify;" class="pb-5 animate__animated animate__bounceInRight wow" data-wow-delay=".3s">
                        Nous sommes bien plus qu'une plateforme de mise en
                        relation commerciale. Nous sommes des facilitateurs de
                        croissance économique, des champions du contenu local et
                        des partenaires de confiance pour les entreprises en
                        Guinée. Notre mission est de dynamiser le marché de la
                        sous-traitance en favorisant des partenariats durables
                        et en accompagnant l'innovation. Avec notre équipe
                        dévouée et notre approche axée sur l'excellence, nous
                        sommes là pour soutenir votre succès et contribuer au
                        développement économique de notre pays.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area pt-5 pb-5">
    <div class="container">
        <div class="row">
            <!-- <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3><span class="counter">14</span>k+</h3>
                    <p>Job Available</p>
                </div>
            </div> -->

            <div class="col-md-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3>
                        <span class="counter">
                            {{ appSettingService.value.total_supplier }}
                        </span>
                    </h3>
                    <p>Fournisseurs</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3>
                        <span class="counter">
                            {{ appSettingService.value.total_buyer }}
                        </span>
                    </h3>
                    <p>Acheteurs</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3>
                        <span class="counter">
                            {{ appSettingService.value.total_request }}
                        </span>
                    </h3>
                    <p>Appels d'offre</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3><span class="counter">{{ appSettingService.value.total_organisme }}</span></h3>
                    <p>Organismes de soutien</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="popular-area pt-100 pb-170">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popular-item">
                    <div class="row align-items-center">
                        <div class="col-lg-7 wow fadeInUp" data-wow-delay=".3s">
                            <img src="./assets/img/upload/about/342x514.png" alt="Popular" />
                        </div>

                        <div class="col-lg-5 wow fadeInUp" data-wow-delay=".6s">
                            <div class="practice-inner">
                                <img src="./assets/img/upload/about/252x306.png" alt="Popular" />
                                <img src="./assets/img/upload/about/252x213.png" alt="Popular" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="popular-item popular-right animate__animated animate__bounceInRight wow" data-wow-delay=".3s">
                    <div class="section-title text-start">
                        <h2>Notre Mission</h2>
                    </div>
                    <p style="text-align: justify;" class="pb-5 animate__animated animate__bounceInRight wow" data-wow-delay=".3s">
                        La BSTP s'engage à promouvoir le contenu local en
                        habilitant les entreprises guinéennes à réaliser leur
                        plein potentiel. Nous offrons un accès aux opportunités
                        d'affaires, un renforcement des capacités, des solutions
                        de financement et la création de partenariats, tout en
                        cultivant l'excellence, l'inclusivité, l'intégrité et
                        l'innovation.
                    </p>
                    <div class="section-title text-start pt-5 animate__animated animate__bounceInRight wow" data-wow-delay=".3s">
                        <h2>Notre Equipe</h2>
                    </div>
                    <p style="text-align: justify;" class="animate__animated animate__bounceInRight wow" data-wow-delay=".3s">
                        L'équipe de la BSTP est composée de professionnels
                        dévoués et passionnés, engagés à soutenir le
                        développement économique de la Guinée. Forts de notre
                        expertise et de notre engagement envers l'excellence,
                        nous travaillons chaque jour pour offrir à nos membres
                        les meilleures opportunités commerciales et les
                        solutions les plus innovantes.
                    </p>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-lg-6 animate__animated animate__bounceInUp wow" data-wow-delay=".3s">
                    <div class="popular-item popular-right">
                        <div class="section-title text-start">
                            <h2>Nos Offres </h2>
                        </div>
                        <p style="text-align: justify;">
                            Explorez nos services personnalisés conçus pour répondre à vos besoins spécifiques en matière
                            d'opportunités d'affaires, de solutions de financement, de renforcement des capacités et de
                            création de partenariats durables. Chez BSTP, nous sommes là pour vous aider à prospérer.
                        </p>

                    </div>
                </div>
                <div class="col-lg-6 animate__animated animate__bounceInUp wow" data-wow-delay=".3s">
                    <div class="popular-item popular-right">
                        <div class="section-title text-start">
                            <h2>Nos Engagements </h2>
                        </div>
                        <p style="text-align: justify;">
                            Nous nous engageons à vous fournir des services de la plus haute qualité, dans le respect de
                            l'inclusivité, de l'intégrité et de l'innovation. Avec BSTP, vous pouvez compter sur une éthique
                            de travail irréprochable et un dévouement sans faille à votre succès.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
