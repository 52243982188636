<ng-template #errorLoading>
    <app-preloader/>
</ng-template>
<ng-container *ngIf="appSettingService.value, else errorLoading">
    <app-navbar *ngIf="!authService.isAuthenticated()" />

    <ng-container *ngIf="authService.value">
        <app-buyer-navbar *ngIf="authService.isAuthenticated() && (authService.value.role.code_name == 'buyer' || authService.value.role.code_name == 'buyer_collegue')" />
        <app-supplier-navbar *ngIf="authService.isAuthenticated() && (authService.value.role.code_name == 'supplier' || authService.value.role.code_name == 'supplier_collegue')" />
        <app-organisme-navbar *ngIf="authService.isAuthenticated() && (authService.value.role.code_name == 'cabinet' || authService.value.role.code_name == 'organisme')" />
    </ng-container>

    <router-outlet />

    <app-footer />
<!--     <app-footer-logged  *ngIf="authService.isAuthenticated()" />-->
</ng-container>

<!-- <ng-template #errorLoading>
    <ng-container class="text-center p-5" *ngIf="status == 504">
        <img src="./assets/img/LOGO_BSTP.png" width="200" alt="">
        <app-maintenance />
    </ng-container>

    <ng-container *ngIf="status != 504">
        <app-error-server />
    </ng-container>
</ng-template> -->
