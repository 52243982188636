import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MemberModel } from 'src/models/member.model';

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private http: HttpClient) { }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}member/`) as Observable<any>;
  }

  create(member: MemberModel): Observable<any> {
    return this.http.post(`${environment.apiUrl}member/`, member) as Observable<any>;
  }

  update(slug: string, member: MemberModel): Observable<any> {
    return this.http.put(`${environment.apiUrl}member/${slug}/`, member) as Observable<any>;
  }

  delete(slug: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}member/${slug}/`) as Observable<any>;
  }

  getSupplierAssocies(slug: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}member/get_supplier_associes/${slug}/`) as Observable<any>;
  }
}
