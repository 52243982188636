<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2 *ngIf="article">{{ article.title.toUpperCase() }}</h2>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li *ngIf="article"><a routerLink="/blog">ACTUALITÉS</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li *ngIf="article">{{ article.title.toUpperCase() }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-5 pb-100">
    <div class="container" *ngIf="article">
        <div class="row">
            <div class="col-lg-12">
                <div class="blog-details-item">
                    <div class="blog-details-img">
                        <img [src]="article.media" height="600" [alt]="article.title">
                        <h3 class="mt-5">{{ article.title }}</h3>

                        <ul>
                            <li><i class="icofont-calendar"></i>{{ article.published_on|date:'d MMMM yyyy' }}</li>
                            <li><i class="icofont-user-alt-7"></i><a [routerLink]="article.slug">{{ article.author.first_name }}</a></li>
                        </ul>

                        <div [innerHTML]="article.summary" style="text-align: justify; font-size: 18px; color: black;"></div>
                    </div>

                    <div class="blog-details-social">
                        <span>Share:</span>

                        <!-- <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul> -->

                        <share-buttons theme="material-dark" show="5" [image]="article.media"></share-buttons>
                    </div>

                    <!-- <div class="blog-details-nav pt-5">
                        <div class="previous-left" *ngIf="article.previous_article != null">
                            <a style="cursor: pointer;" (click)="onButtonClicked(article.previous_article)">Précédent</a>
                        </div>

                        <div class="previous-right" **ngIf="article.next_article != null">
                            <a style="cursor: pointer;" (click)="onButtonClicked(article.next_article)">Suivant</a>
                        </div>
                    </div> -->

                    <!--div class="blog-details-form">
                        <h3>Drop Your Comment</h3>

                        <form id="contactForm">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                            </div>

                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                            </div>

                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                            </div>

                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Your Message"></textarea>
                            </div>

                            <div class="text-left">
                                <button type="submit" class="btn blog-details-btn">Post Your Comment</button>
                            </div>
                        </form>
                    </div-->
                </div>
            </div>

            <!-- <div class="col-lg-4">
                <div class="blog-details-item">
                    <div class="single-resume-category">
                        <h3>CATÉGORIES</h3>

                        <ul>
                            <li><a routerLink="/">Web Developer</a><span>(5)</span></li>
                            <li><a routerLink="/">Web Designer</a><span>(2)</span></li>
                            <li><a routerLink="/">UX/UI Designer</a><span>(7)</span></li>
                            <li><a routerLink="/">Marketing</a><span>(0)</span></li>
                            <li><a routerLink="/">SEO</a><span>(1)</span></li>
                            <li><a routerLink="/">Networking</a><span>(0)</span></li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
