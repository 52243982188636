import { Component, OnInit } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { CategoriesModel } from "src/models/categories.model";
import { Requests } from "src/models/requests";
import { CategoriesService } from "src/services/categories/categories.service";
import { RequestsService } from "src/services/requests/requests.service";

@Component({
    selector: "app-requests",
    templateUrl: "./requests.component.html",
    styleUrls: ["./requests.component.scss"],
})
export class RequestsComponent implements OnInit {
    requests: Array<Requests>;
    categories: Array<CategoriesModel>;
    loading: boolean;
    slug: string;
    search: string;
    filtRequests: Array<Requests>;
    page = 1;
    pageSize = 50;
    collectionSize = 0;

    constructor(
        private requestService: RequestsService,
        public categoryService: CategoriesService,
        private route: ActivatedRoute,
        private meta: Meta
    ) {}

    ngOnInit(): void {
        this.meta.addTag({ name: 'description', content: "Appels d'offres" });
        this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
        this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });

        this.slug = this.route.snapshot.params["slug"];
        console.log(this.slug)
        this.loading = true;
        if (this.slug != null) {
            this.requestService.filter(this.slug).subscribe({
                next: (data) => {
                    this.loading = false;
                    this.requests = data;
                    this.collectionSize = this.requests.length;
                    this.filtRequests = this.requests.slice(0, this.pageSize);
                },
                error: (error) => {
                    this.loading = false;
                },
            });
        } else {
            this.requestService.all().subscribe({
                next: (data) => {
                    this.loading = false;
                    this.requests = data;
                    this.collectionSize = this.requests.length;
                    this.filtRequests = this.requests.slice(0, this.pageSize);
                },
                error: (error) => {
                    this.loading = false;
                },
            });
        }
    }

    onClick(slug) {
        this.loading = true;
        this.requestService.filter(slug).subscribe({
            next: (data) => {
                this.loading = false;
                console.log(data);
                this.requests = data;
            },
            error: (error) => {
                this.loading = false;
            },
        });
    }

    get filteredRequests() {
        if (
            this.search === null ||
            this.search === undefined ||
            this.search === ""
        ) {
            return this.requests;
        }
        return this.requests.filter((request) =>
            request.name.toLowerCase().includes(this.search.toLowerCase())
        );
    }

    refreshCompanies() {
        // Map the formations to add an `id` property to each one.
        const mappedCompanies = this.requests.map((formation, i) => ({ id: i + 1, ...formation }));

        // Calculate the starting and ending indices for pagination.
        const startIndex = (this.page - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;

        // Slice the mapped formations array to get only the formations for the current page.
        this.filtRequests = mappedCompanies.slice(startIndex, endIndex);
    }

}
