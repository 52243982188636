import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppSettingModel } from 'src/models/app-setting.model';
import { AppSettingService } from 'src/services/app-setting/app-setting.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(public appSettingService: AppSettingService, private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTag({ name: 'description', content: "Politique de Confidentialité" });
    this.meta.addTag({ name: 'keywords', content: 'BSTP Guinée, BSTP, bstpgn, ' });
    this.meta.addTag({ name: 'author', content: 'BSTP Guinée' });
  }
}
