import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FaqModel } from 'src/models/faq.model';


@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private faqsSubject: BehaviorSubject<Array<FaqModel>>;
  public faqs$: Observable<Array<FaqModel>>;

  constructor(private http: HttpClient) {
    this.faqsSubject = new BehaviorSubject<Array<FaqModel>>([]);
    this.faqs$ = this.faqsSubject.asObservable();
  }

  public get value(): Array<FaqModel> {
    return this.faqsSubject.value;
  }

  update(faqs: Array<FaqModel>){
    this.faqsSubject.next(faqs);
  }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}faq/`) as Observable<any>;
  }
}
