import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from 'src/models/user.model';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private userSubject: BehaviorSubject<User | null>;
    public user$: Observable<User | null>;
    private refreshTokenTimeout: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private jwtHelper: JwtHelperService
    ) {
        this.userSubject = new BehaviorSubject<User | null>(null);
        this.user$ = this.userSubject.asObservable();
    }

    /** Getter pour récupérer l'utilisateur courant */
    public get value(): User | null {
        return this.userSubject.value;
    }

    /** Récupérer le token JWT */
    getToken() {
        return this.jwtHelper.tokenGetter();
    }

    /** Mettre à jour l'utilisateur courant */
    update(user: User): void {
        this.userSubject.next(user);
    }

    /** Authentification d'un utilisateur */
    login(user: User): Observable<any> {
        return this.http.post(`${environment.apiUrl}auth/token/`, user).pipe(
            tap((response: any) => {
                localStorage.setItem('access', response.access);
                localStorage.setItem('refresh', response.refresh);
                this.startRefreshTokenTimer(); // Démarrer le timer pour rafraîchir le token
            }),
            catchError((error) => {
                this.showErrorAlert(
                    'Erreur de connexion',
                    'Impossible de se connecter. Vérifiez vos identifiants.'
                );
                return throwError(() => error);
            })
        );
    }

    /** Vérifie si l'utilisateur est authentifié */
    isAuthenticated(): boolean {
        const token = this.getToken();
        return !!token && !this.jwtHelper.isTokenExpired();
    }

    /** Déconnecte l'utilisateur */
    logout(): void {
        Swal.fire({
            title: 'Déconnexion',
            text: 'Voulez-vous vraiment vous déconnecter ?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#006481',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, je confirme',
            cancelButtonText: "Non, j'annule",
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.clearSession();
                window.location.replace('/'); // Optionnel : recharger la page
            }
        });
    }

    /** Rafraîchir le token JWT */
    refreshToken(): Observable<any> {
        const refresh = localStorage.getItem('refresh');
        if (!refresh) {
            this.handleSessionExpired();
            return throwError(() => new Error('Refresh token manquant.'));
        }

        return this.http
            .post(`${environment.apiUrl}auth/refresh/`, { refresh })
            .pipe(
                tap((response: any) => {
                    localStorage.setItem('access', response.access);
                    this.startRefreshTokenTimer();
                }),
                catchError((error) => {
                    this.handleSessionExpired();
                    return throwError(() => error);
                })
            );
    }

    /** Démarrer le timer pour rafraîchir automatiquement le token */
    private startRefreshTokenTimer(): void {
        const refresh = localStorage.getItem('refresh');
        if (refresh) {
            const authToken = this.jwtHelper.decodeToken(refresh);
            const expiresAt = new Date(authToken.exp * 1000).getTime();
            const timeout = expiresAt - Date.now() - 60 * 1000;

            this.refreshTokenTimeout = setTimeout(() => {
                this.refreshToken().subscribe({
                    error: () => this.handleSessionExpired(),
                });
            }, timeout);
        }
    }

    /** Arrêter le timer */
    private stopRefreshTokenTimer(): void {
        clearTimeout(this.refreshTokenTimeout);
    }

    /** Gestion de l'expiration de session */
    private handleSessionExpired(): void {
        this.stopRefreshTokenTimer();
        this.clearSession();

        Swal.fire({
            title: 'Session expirée',
            text: "Votre session a expiré. Veuillez vous reconnecter.",
            icon: 'error',
            confirmButtonColor: '#006481',
            confirmButtonText: 'Se reconnecter',
            allowOutsideClick: false,
        }).then(() => {
            this.router.navigate(['/login']);
        });
    }

    /** Nettoyer les données de session */
    private clearSession(): void {
        localStorage.clear();
        this.userSubject.next(null);
    }

    /** Afficher une alerte d'erreur */
    private showErrorAlert(title: string, text: string): void {
        Swal.fire({
            title,
            text,
            icon: 'error',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
        });
    }
}
