import { inject } from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/services/auth/auth.service';
import Swal from 'sweetalert2';

export const RolesGuard = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    router: Router
): boolean => {
    const auth = inject(AuthService);
    const jwtHelper = inject(JwtHelperService);

    // Récupérer les rôles nécessaires depuis les données de la route
    const roles = route.data['role'];

    // Décoder le token JWT
    const token = auth.getToken();

    if (!token || jwtHelper.isTokenExpired()) {
        Swal.fire({
            title: 'Accès refusé',
            text: 'Votre session a expiré. Veuillez vous reconnecter.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: "#006481",
            color: "#006481",
            iconColor: "#E40E20",
            cancelButtonColor: "#d33",
            confirmButtonText: "FERMER",
            allowOutsideClick: () => false,
        }).then((result) => {
            if (result.isConfirmed) {
                router.navigate(['/login']);
            }
        });
        return false;
    }

    const payload = jwtHelper.decodeToken();

    // Vérification des rôles
    if (roles.includes(payload.role)) {
        return true;
    }

    Swal.fire({
        title: 'Accès refusé',
        text: "Vous n'avez pas les droits nécessaires.",
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: "#006481",
        color: "#006481",
        iconColor: "#E40E20",
        cancelButtonColor: "#d33",
        confirmButtonText: "FERMER",
        allowOutsideClick: () => false,
    });
    return false;
};
