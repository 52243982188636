import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/common/preloader/preloader.component";
import { NavbarComponent } from "./components/common/navbar/navbar.component";
import { FooterComponent } from "./components/common/footer/footer.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { CreateAccountComponent } from "./components/pages/create-account/create-account.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { TermsAndConditionsComponent } from "./components/pages/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
    provideHttpClient,
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BuyerNavbarComponent } from "./components/common/buyer-navbar/buyer-navbar.component";
import { SupplierNavbarComponent } from "./components/common/supplier-navbar/supplier-navbar.component";
import { JwtModule } from "@auth0/angular-jwt";
import { NgxEditorModule } from "ngx-editor";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import {
    APP_BASE_HREF,
    NgOptimizedImage,
    registerLocaleData,
} from "@angular/common";
import { AuthInterceptor } from "src/interceptors/auth.interceptor";
import {
    RECAPTCHA_BASE_URL,
    RECAPTCHA_LANGUAGE,
    RECAPTCHA_SETTINGS,
    RECAPTCHA_V3_SITE_KEY,
    ReCaptchaV3Service,
    RecaptchaFormsModule,
    RecaptchaLoaderService,
    RecaptchaModule,
    RecaptchaSettings,
} from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SubscriptionSuccessComponent } from "./components/pages/subscription-success/subscription-success.component";
import { LoadingModule } from "./loading/loading.module";
import { AuthService } from "src/services/auth/auth.service";
import { appInitializer } from "src/init/app-initializer";
import { FeedbackComponent } from "./components/pages/feedback/feedback.component";
import { HomeComponent } from "./components/pages/home/home.component";
import { AppSettingService } from "src/services/app-setting/app-setting.service";
import { FooterLoggedComponent } from "./components/common/footer-logged/footer-logged.component";
import { QuicklinkModule } from "ngx-quicklink";
import { ArticleService } from "src/services/article/article.service";
import { BuyerService } from "src/services/buyer/buyer.service";
import { CategoriesService } from "src/services/categories/categories.service";
import { CommercialesService } from "src/services/commerciales/commerciales.service";
import { CommmuneService } from "src/services/commune/commmune.service";
import { CompanyService } from "src/services/company/company.service";
import { CompanyTypService } from "src/services/company_type/company-typ.service";
import { CountriesService } from "src/services/countries/countries.service";
import { FaqService } from "src/services/faq/faq.service";
import { LabelisationService } from "src/services/labelisation/labelisation.service";
import { MemberService } from "src/services/member/member.service";
import { PartnershipService } from "src/services/partnership/partnership.service";
import { PrefectureService } from "src/services/prefecture/prefecture.service";
import { RegionService } from "src/services/region/region.service";
import { RequestTypeService } from "src/services/request-type/request-type.service";
import { RequestsService } from "src/services/requests/requests.service";
import { RoleService } from "src/services/role/role.service";
import { SpecialGroupService } from "src/services/special_group/special-group.service";
import { StaffService } from "src/services/staff/staff.service";
import { SubmissionService } from "src/services/submission/submission.service";
import { TestimonialsService } from "src/services/testimonials/testimonials.service";
import { UserService } from "src/services/user/user.service";
import { RequestsComponent } from "./components/pages/requests/requests.component";
import { RequestDetailComponent } from "./components/pages/request-detail/request-detail.component";
import { MissionsComponent } from "./components/pages/missions/missions.component";
import { BstpTeamComponent } from "./components/pages/bstp-team/bstp-team.component";
import { GuidesComponent } from "./components/pages/guides/guides.component";
import { PartnersComponent } from "./components/pages/partners/partners.component";
import localeFr from "@angular/common/locales/fr";

import { MailConfirmedComponent } from "./components/pages/mail-confirmed/mail-confirmed.component";
import { PaymentFirstStepComponent } from "./components/pages/payment-first-step/payment-first-step.component";
import { PartenaireTypeService } from "src/services/partenaire-type/partenaire-type.service";

import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { ChangePasswordComponent } from "./components/pages/change-password/change-password.component";
import { ForgotPasswordComponent } from "./components/pages/forgot-password/forgot-password.component";
import { EmailSentSuccessfullyComponent } from "./components/pages/email-sent-successfully/email-sent-successfully.component";
import { PaymentSuccessfulComponent } from "./components/pages/payment-successful/payment-successful.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { CreateOldCompanyComponent } from "./components/pages/create-old-company/create-old-company.component";
import { OrganismeNavbarComponent } from "./components/common/organisme-navbar/organisme-navbar.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { ProgrammeDAmeliorationDeContenuComponent } from "./components/pages/programme-d-amelioration-de-contenu/programme-d-amelioration-de-contenu.component";
import { LabelisationDesPmePmiComponent } from "./components/pages/labelisation-des-pme-pmi/labelisation-des-pme-pmi.component";
import { MaintenanceComponent } from './components/pages/maintenance/maintenance.component';
import { ErrorServerComponent } from "./components/pages/error-server/error-server.component";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";

export function tokenGetter() {
    return localStorage.getItem("access");
}

function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const I18N_CONFIG = {
    defaultLanguage: 'french', // this name need to be the same as the JSON file
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }

// Enregistrement de la localisation française
registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        FooterComponent,
        AboutComponent,
        LoginComponent,
        CreateAccountComponent,
        ErrorComponent,
        FaqComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        ContactComponent,
        BlogDetailsComponent,
        BlogComponent,
        BuyerNavbarComponent,
        SupplierNavbarComponent,
        SubscriptionSuccessComponent,
        FeedbackComponent,
        HomeComponent,
        FooterLoggedComponent,
        RequestsComponent,
        RequestDetailComponent,
        MissionsComponent,
        BstpTeamComponent,
        GuidesComponent,
        PartnersComponent,
        MailConfirmedComponent,
        PaymentFirstStepComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent,
        EmailSentSuccessfullyComponent,
        PaymentSuccessfulComponent,
        CreateOldCompanyComponent,
        OrganismeNavbarComponent,
        ProgrammeDAmeliorationDeContenuComponent,
        LabelisationDesPmePmiComponent,
        MaintenanceComponent,
        ErrorServerComponent,
        // DateUppercasePipe,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot(I18N_CONFIG),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: [
                    "api.bstpgn.com",
                    "bstpgn.com",
                ],
                disallowedRoutes: ["https://bstpgn.com/static/"],
            },
        }),
        NgxEditorModule,
        NgxMaskDirective,
        NgxMaskPipe,
        RecaptchaModule,
        RecaptchaFormsModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            // registrationStrategy: 'registerWhenStable:30000',
            registrationStrategy: "registerImmediately",
        }),
        LoadingModule,
        QuicklinkModule,
        ShareButtonsModule.withConfig({
            debug: false,
        }),
        ShareIconsModule,
        NgxSkeletonLoaderModule,
        NgbPaginationModule,
        NgOptimizedImage,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "fr-FR" },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [
                AuthService,
                AppSettingService,
                PrefectureService,
                RegionService,
                CommmuneService,
                CountriesService,
                CategoriesService,
                StaffService,
                FaqService,
                PartenaireTypeService,
            ],
        },
        provideNgxMask(),
        { provide: APP_BASE_HREF, useValue: "/" },
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useValue: "fr",
        },
        {
            provide: RECAPTCHA_BASE_URL,
            useValue:
                "https://www.google.com/recaptcha/api.js?render=explicit&amp;onload=onloadCallback", // use recaptcha.net script source for some of our users
        },
        ReCaptchaV3Service,
        RecaptchaLoaderService,
        AppSettingService,
        AuthService,
        ArticleService,
        BuyerService,
        CategoriesService,
        CommercialesService,
        CommmuneService,
        CompanyService,
        CompanyTypService,
        CountriesService,
        FaqService,
        LabelisationService,
        MemberService,
        PartnershipService,
        PrefectureService,
        RegionService,
        RequestTypeService,
        RequestsService,
        RoleService,
        SpecialGroupService,
        StaffService,
        SubmissionService,
        TestimonialsService,
        UserService,
        provideHttpClient(),
        TranslatePipe
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(router: Router) {
        router.events.pipe(
          filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
          (window as any).gtag('config', 'G-L2R3SF2QNG', {
            page_path: event.urlAfterRedirects
          });
        });
    }
}
