<header class="bg-light">
    <div class="navbar-area fixed-top p-0">
        <div class="mobile-nav">
            <a routerLink="" class="logo">
                <img [src]="appSettingService.value.footer_logo"
                style="width: 200px;"
                alt="Logo" />
            </a>
        </div>

        <div class="main-nav p-0">

            <div class="flag"></div>
            <div class="container">
                <div class="row">
                    <div class="col-sm-2 m-auto d-none d-lg-block text-start">
                        <img
                            [src]="appSettingService.value.logo"
                            width="300"
                            alt="Logo"
                            class="logo" />
                    </div>
                    <div class="col-md-12 col-lg-10">
                        <div class="row">
                            <div class="col-lg-12">
                                <nav class="navbar navbar-expand-md navbar-light">
                                    <ul class="navbar-nav d-flex bd-highlight">
                                        <li class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <i class="icofont-location-pin text-default"></i>
                                                <span>
                                                    <strong>Guinée - Conakry </strong>
                                                    <br/>
                                                    {{ appSettingService.value.address }}
                                                </span>
                                            </h6>
                                        </li>
                                        <li class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <i class="icofont-phone text-default"></i>
                                                <span>
                                                    <strong>+224 {{ appSettingService.value.phone_number2 }} </strong>
                                                    <br/>
                                                    Appelez-nous
                                                </span>
                                            </h6>
                                        </li>
                                        <li class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <i class="icofont-email text-default"></i>
                                                <span>
                                                    <strong>{{ appSettingService.value.email }} </strong>
                                                    <br/>
                                                    Contactez-nous
                                                </span>
                                            </h6>
                                        </li>

                                        <li class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <i class="icofont-facebook text-default"></i>
                                            </h6>
                                        </li>

                                        <li class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <i class="icofont-youtube-play text-default"></i>
                                            </h6>
                                        </li>

                                        <li class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <i class="icofont-twitter text-default"></i>
                                            </h6>
                                        </li>

                                        <li class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <i class="icofont-instagram text-default"></i>
                                            </h6>
                                        </li>

                                        <li style="cursor: pointer;" routerLink="login"
                                            class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <span class="mt-2">
                                                    <strong>Se connecter </strong>
                                                </span>
                                            </h6>
                                        </li>

                                        <li
                                            style="cursor: pointer;" routerLink="create-account"
                                            class="nav-item p-2 flex-fill bd-highlight">
                                            <h6>
                                                <span class="mt-2">
                                                    <strong>S'enregistrer </strong>
                                                </span>
                                            </h6>
                                        </li>
                                    </ul>
                                </nav>
                                <hr/>
                            </div>
                            <div class="col-lg-12">
                                <nav class="navbar navbar-expand-md navbar-light">
                                    <!-- <a class="navbar-brand" routerLink="">
                                            <img
                                                [src]="appSettingService.value.logo"
                                                width="300"
                                                alt="Logo"
                                                class="p-0 m-0 logo"
                                        /></a>
                                     -->
                                    <div
                                        class="collapse navbar-collapse mean-menu"
                                        id="navbarSupportedContent">
                                        <ul class="navbar-nav">
                                            <li class="nav-item">
                                                <a routerLink="/"
                                                   class="nav-link"
                                                   routerLinkActive="active"
                                                   [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }">
                                                    ACCUEIL
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a routerLink="/requests"
                                                   class="nav-link"
                                                   routerLinkActive="active"
                                                   [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }">
                                                    OFFRES
                                                </a>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#"
                                                   class="nav-link dropdown-toggle">
                                                    QUI SOMMES-NOUS?
                                                    <i class="icofont-simple-down"></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <a
                                                            routerLink="/about"
                                                            class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                        >A PROPOS DE LA
                                                            BSTP</a
                                                        >
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            routerLink="/bstp-team"
                                                            class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                        >LA BSTP TEAM</a
                                                        >
                                                    </li>
                                                    <!-- <li class="nav-item">
                                                        <a
                                                            routerLink="/missions"
                                                            class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >MISSIONS</a
                                                        >
                                                    </li> -->
                                                    <li class="nav-item">
                                                        <a
                                                            routerLink="/faq"
                                                            class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                        >FAQs</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    href="#"
                                                    class="nav-link dropdown-toggle"
                                                >PERFORMEZ
                                                    <i class="icofont-simple-down"></i></a>

                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <a
                                                            routerLink="/programme-d-amelioration-de-contenu"
                                                            class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                        >PROGRAMME D'AMELIORATION CONTINU</a
                                                        >
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            routerLink="/labelisation-des-pme-pmi"
                                                            class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                        >LABELISATION DES PME/PMI</a
                                                        >
                                                    </li>

                                                </ul>
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    routerLink="/guides"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                >GUIDE UTILISATEUR</a
                                                >
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/partners"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                >PARTENAIRES</a
                                                >
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/blog"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                >ACTUALITÉS</a
                                                >
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/contact"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                >CONTACTEZ-NOUS</a
                                                >
                                            </li>
                                        </ul>

                                        <!-- <ul class="navbar-nav navbar-right">
                                            <li class="nav-item">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="nav-link dropdown-toggle sign-up-btn"
                                                    >CONNEXION<i class="icofont-simple-down"></i
                                                ></a>

                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <a
                                                            routerLink="/login"
                                                            class="nav-link"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >SE CONNECTER</a
                                                        >
                                                    </li>
                                                    <li class="nav-item">
                                                        <a
                                                            routerLink="/create-account"
                                                            class="nav-link"
                                                            style="cursor: pointer"
                                                            >S'ENREGISTER</a
                                                        >
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul> -->
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
