import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoriesModel } from 'src/models/categories.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private categoriesSubject: BehaviorSubject<Array<CategoriesModel>>;
  public categories$: Observable<Array<CategoriesModel>>;

  constructor(private http: HttpClient) {
    this.categoriesSubject = new BehaviorSubject<Array<CategoriesModel>>([]);
    this.categories$ = this.categoriesSubject.asObservable();
  }

  public get value(): Array<CategoriesModel> {
    return this.categoriesSubject.value;
  }

  update(categories: Array<CategoriesModel>){
    this.categoriesSubject.next(categories);
  }

  list(): Observable<any> {
    return this.http.get(`${environment.apiUrl}categories/`) as Observable<any>;
  }
}
