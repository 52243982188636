import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleModel } from 'src/models/article.model';
import { ArticleService } from 'src/services/article/article.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  article: ArticleModel;
  loading: boolean;
  slug: string;

  constructor(private articleService: ArticleService, private route: ActivatedRoute, private router: Router, private titleService: Title) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.params.slug;
    this.loading = true;
    this.articleService.retrieve(this.slug).subscribe({
      next: response => {
        this.loading = false;
        this.article = response;
        this.titleService.setTitle(`BSTP | ${response.title.toUpperCase()}`)
      },
      error: error => {
        this.loading = false;
      }
    })
  }


  onButtonClicked(slug: string) {
    this.router.navigateByUrl(`/blog/${slug}`);
  }

}
