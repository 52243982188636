import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Editor } from "ngx-editor";
import { CategoriesModel } from "src/models/categories.model";
import { CommuneModel } from "src/models/commune.model";
import { Company } from "src/models/company.model";
import { CompanyTypeModel } from "src/models/company_type.model";
import { CountriesModel } from "src/models/countries.model";
import { Prefecture } from "src/models/prefecture";
import { Region } from "src/models/region";
import { Role } from "src/models/role.model";
import { CategoriesService } from "src/services/categories/categories.service";
import { CommmuneService } from "src/services/commune/commmune.service";
import { CompanyService } from "src/services/company/company.service";
import { CompanyTypService } from "src/services/company_type/company-typ.service";
import { CountriesService } from "src/services/countries/countries.service";
import { PrefectureService } from "src/services/prefecture/prefecture.service";
import { RegionService } from "src/services/region/region.service";
import { RoleService } from "src/services/role/role.service";
import { UserService } from "src/services/user/user.service";
import Swal from "sweetalert2";

declare let $: any;

@Component({
    selector: "app-create-account",
    templateUrl: "./create-account.component.html",
    styleUrls: ["./create-account.component.scss"],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
    form: FormGroup = new FormGroup({});
    type: string;
    roles: Array<Role>;
    companyTypes: Array<CompanyTypeModel>;
    sectors: Array<Company>;
    codeName: string = "";
    categories: Array<CategoriesModel>;
    regions: Array<Region>;
    prefectures: Array<Prefecture>;
    communes: Array<CommuneModel>;
    countries: Array<CountriesModel>;
    file: File;
    @Input() password;

    search: string;

    loading: boolean;

    editor: Editor;
    html = "";

    step1: boolean;
    step2: boolean;

    onStep1Changed() {
        this.step1 = true;
    }

    onStep2Changed() {
        this.step2 = true;
    }
    onBack1Changed() {
        this.step1 = false;
    }

    onBack2Changed() {
        this.step2 = false;
    }

    constructor(
        private fb: FormBuilder,
        private roleService: RoleService,
        private companyTypeService: CompanyTypService,
        private companyService: CompanyService,
        private userService: UserService,
        public categoryService: CategoriesService,
        public regionService: RegionService,
        private prefectureService: PrefectureService,
        private communeService: CommmuneService,
        public countryService: CountriesService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private meta: Meta,
        private router: Router
    ) {}

    initForm() {
        this.meta.addTag({ name: "description", content: "S'inscrire" });
        this.meta.addTag({
            name: "keywords",
            content: "BSTP Guinée, BSTP, bstpgn, ",
        });
        this.meta.addTag({ name: "author", content: "BSTP Guinée" });

        this.form = this.fb.group(
            {
                first_name: this.fb.control(null, [Validators.required]),
                last_name: this.fb.control(null, [Validators.required]),
                gender: this.fb.control("M", [Validators.required]),
                date_of_birth: this.fb.control(null, [Validators.required]),
                nationality: this.fb.control(94, [Validators.required]),
                email: this.fb.control(null, [Validators.required]),
                profession: this.fb.control(null, [Validators.required]),
                phone_number: this.fb.control(null, [Validators.required]),
                role: this.fb.control(null, []),
                password: this.fb.control(null, [
                    // Validators.pattern(
                    //     /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
                    // ),
                    Validators.required
                ]),
                password1: this.fb.control(null, [Validators.required]),
                address: this.fb.control(null, []),
                // username: this.fb.control(null, []),
                supplier: this.fb.group({
                    name: this.fb.control(null, [Validators.required]),
                    type: this.fb.control(null, [Validators.required]),
                    description: this.fb.control(null, [Validators.required]),
                    rccm: this.fb.control(null, [Validators.required]),
                    year_of_registration: this.fb.control(null, [Validators.required]),
                    email: this.fb.control(null, [Validators.required]),
                    phone_number: this.fb.control(null, [Validators.required]),
                    website: this.fb.control(null, []),
                    categories: this.fb.array([], [Validators.required]),
                    region: this.fb.control(null, [Validators.required]),
                    prefecture: this.fb.control(null, []),
                    commune: this.fb.control(null, []),
                    address: this.fb.control(null, [Validators.required]),
                    // registered_by: this.fb.control(null, []),
                    terms_and_conditions: this.fb.control(null, [
                        Validators.required
                    ]),
                    recaptcha: this.fb.control(null, [Validators.required]),
                    search: this.fb.control(null, []),
                }),
            },
            { validators: this.checkPasswords }
        );
    }

    onCheckboxChange(e: any) {
        const listSectors: FormArray = this.form
            .get("supplier")
            .get("categories") as FormArray;

        if (listSectors.controls.length < 5) {
            if (e.target.checked) {
                listSectors.push(this.fb.control(e.target.value));
            } else {
                let i: number = 0;
                listSectors.controls.forEach((item: any) => {
                    if (item.value == e.target.value) {
                        listSectors.removeAt(i);
                        return;
                    }
                    i++;
                });
            }
        } else {
            e.target.checked = false;
            let i: number = 0;
            listSectors.controls.forEach((item: any) => {
                if (item.value == e.target.value) {
                    listSectors.removeAt(i);
                    return;
                }
                i++;
            });
            Swal.fire(
                {title: "Avertissement",
                text: "Vous avez le droit de cocher au maximum que 5 secteurs d'activités",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: '#00533e',
                color: '#006481',
                iconColor: '#e40e20',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Fermer',
                cancelButtonText: "Non, j'annule",
                allowOutsideClick: () => false,
            });
        }
        console.log(listSectors.controls.length);
        // console.log(listSectors.value)
    }

    checkPasswords: ValidatorFn = (
        group: AbstractControl
    ): ValidationErrors | null => {
        let pass = group.get("password").value;
        let confirmPass = group.get("password1").value;
        return pass === confirmPass ? null : { notSame: true };
    };

    ngOnInit(): void {
        // $.getScript("../assets/js/register.js");
        this.initForm();
        this.editor = new Editor();

        this.roleService.list().subscribe((data: any) => {
            this.roles = data;
            this.roles.forEach((value, index) => {
                if (value.code_name == "supplier") {
                    this.form.get("role").setValue(value.id);
                }
            });
        });

        // this.regionService.list().subscribe((data: any) => {
        //     this.regions = data;
        // });

        // this.countryService
        //     .all()
        //     .subscribe((data: any) => (this.countries = data));

        this.companyTypeService
            .list()
            .subscribe((data: any) => (this.companyTypes = data));

        this.companyService
            .list()
            .subscribe((data: any) => (this.sectors = data));

        // this.categoryService
        //     .list()
        //     .subscribe((data: any) => (this.categories = data));
    }

    // make sure to destory the editor
    ngOnDestroy(): void {
        this.editor.destroy();
    }

    onToggle(event): void {
        console.log(event.target.value);
        this.roles.forEach((value, index) => {
            if (value.id == event.target.value) {
                this.type = value.code_name;
            }
        });
    }

    onSubmit() {

        console.log(this.form.errors)
        console.log(this.form.value)

        if (this.form.invalid) {
            for (const control of Object.keys(this.form.controls)) {
                this.form.controls[control].markAsTouched();
            }

            for(const control of Object.keys((this.form.get('supplier') as FormArray).controls)) {
                (this.form.get('supplier') as FormArray).controls[control].markAsTouched();
            }

            return;
        }


        this.loading = true;
        this.recaptchaV3Service
        .execute("importantAction")
        .subscribe((token: string) => {
            console.debug(`Token [${token}] generated`);
        });

        // this.form.get("username").setValue(this.form.get("email").value);
        // alert(JSON.stringify(this.form.value))
        // console.log(this.form.value)
        this.userService.register(this.form.value).subscribe({
            next: (data: Company) => {
                this.loading = false;
                localStorage.setItem('company_slug', data.slug)
                this.router.navigateByUrl("/registration-success");
            },
            error: (error) => {
                this.loading = false;
                let message = error.error.detail || error.error.non_error_fields[0];
                // console.log(message);
                Swal.fire({
                    title: "Erreur!",
                    text: message,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#006481",
                    color: "#006481",
                    iconColor: "#e40e20",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Fermer",
                    cancelButtonText: "Non, j'annule",
                });
            },
        });
        // Swal.fire("Success", "Information ajoutee avec succes", "success");
        // console.log(this.form.value);
    }

    onRegionsChanged(event) {
        this.prefectures = [];
        this.communes = [];
        // this.prefectureService
        //     .getPrefecturesByRegion(event.target.value)
        //     .subscribe((data: any) => (this.prefectures = data));
        const id = parseInt(event.target.value);
        this.prefectureService.value.forEach((value, index) => {
            if (value.region.id == id) {
                this.prefectures.push(value);
            }
        });
    }

    onPrefecturesChanged(event) {
        // this.communeService
        //     .getCommunesByPrefecture(event.target.value)
        //     .subscribe((data: any) => (this.communes = data));
        this.communes = [];
        const id = parseInt(event.target.value);
        this.communeService.value.forEach((value, index) => {
            if (value.prefecture.id == id) {
                this.communes.push(value);
            }
        });
    }

    onFileChange(filename: string, event: any) {
        if (event.target.files && event.target.files.length) {
            const reader = new FileReader();
            this.file = (event.target as HTMLInputElement).files[0];
            console.log(this.file);
            reader.readAsDataURL(this.file);
            reader.onload = () => {
                this.form.addControl(filename, this.fb.control(reader.result));
            };
        }
    }

    onStrengthChanged(event) {
        console.log(event.target.value);
    }

    get filteredCategories() {
        if (this.search === null || this.search === undefined || this.search === '') {
            return this.categoryService.value;
        }

        return this.categoryService.value.filter(request =>
          request.name.toLowerCase().includes(this.search.toLowerCase())
        );
    }
}
