import { Component, OnInit } from '@angular/core';
import { ArticleModel } from 'src/models/article.model';
import { ArticleService } from 'src/services/article/article.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  articles: Array<ArticleModel>;
  limites: Array<ArticleModel>;
  loading: boolean;
  search: string;

  constructor(private articleService: ArticleService) { }

  ngOnInit(): void {
    this.loading = true;
    this.articleService.list().subscribe({
      next: (response) => {
        this.loading = false;
        this.articles = response;
      },
      error: error => {
        this.loading = false;
      }
    });

    this.articleService.limit().subscribe({
      next: (response) => {
        this.loading = false;
        this.limites = response;
      },
      error: error => {
        this.loading = false;
      }
    })
  }

  get filteredTeam() {
    if (this.search === null || this.search === undefined || this.search === '') {
        return this.articles;
    }

    return this.articles.filter(request =>
      request.title.toLowerCase().includes(this.search.toLowerCase())
    );
  }

}
