import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { UserService } from 'src/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  loading: boolean;

  constructor(private fb: FormBuilder, private userService: UserService, private route: Router) {}

  ngOnInit(): void {
      this.form = this.fb.group({
        email: this.fb.control(null, [Validators.required, Validators.email]),
        recaptcha: this.fb.control(null, [Validators.required]),
      });
  }

  onSubmit() {
    this.loading = true;
    this.userService.forgotPassword(this.form.get('email').value).subscribe({
      next: response => {
        this.loading = false;
        this.route.navigate(['/email-sent-successfully'])
      },
      error: error => {
        console.error(error);
        this.loading = false;
        Swal.fire({
          title: "Confirmation",
          text: error.error.detail,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: '#006481',
          color: '#006481',
          iconColor: '#e40e20',
          cancelButtonColor: '#d33',
          confirmButtonText: 'FERMER',
          allowOutsideClick: () => false,
      }).then(result => {
          if (result.isConfirmed) {
              return;
          }
        });
      }
    })
  }
}
